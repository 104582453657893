import * as Yup from 'yup';
import { useLazyQuery, gql } from '@apollo/client';
import { useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';

// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';

import { LoadingButton } from '@mui/lab';

// graphql
import { LOGIN_LINGUIST } from '../../../../graphQL/queries'
// routes
import { PATH_AUTH_LINGUIST } from '../../../../routes/paths';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  let recaptcha_keys = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const [linguistUsers, { data, error, loading }] = useLazyQuery(LOGIN_LINGUIST, { fetchPolicy: 'no-cache' });
  const captchaRef = useRef(null)
  // const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    wrongPassword: Yup.number(),
    captchaToken: Yup.string().when('wrongPassword', {
      is: (val) => val > 0,
      then: Yup.string().required('Captcha is required').nullable()
    }),
  });

  const defaultValues = {
    remember: true,
    wrongPassword: 0,
    captchaToken: ""
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset, control,
    setError,
    handleSubmit, watch, setValue,
    formState: { errors, isSubmitting },
  } = methods;
  const values = watch();
  // console.log("values", values)
  const navigate = useNavigate()
  const onSubmit = async (data) => {
    try {
      await linguistUsers({
        variables: {
          email: data.email,
          password: data.password,
          recaptcha: data.captchaToken
        }
      })
        .then((res) => {
          console.log(res)
          login(res.data.linguistUsers)
          captchaRef.current.reset();

        })
        .catch((err) => {
          console.log(err)
          setValue("wrongPassword", values.wrongPassword + 1);
          captchaRef.current.reset();

        });
    } catch (error) {
      console.error(error);

    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error && <Alert severity="error">{error.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {values.wrongPassword > 0 &&
          <Controller
            name="captchaToken"
            control={control}
            render={({ field }) => (
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={recaptcha_keys}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />}
        {errors?.captchaToken && <Alert severity="error">{errors?.captchaToken?.message}</Alert>}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH_LINGUIST.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
