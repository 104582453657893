import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// @mui
import {
  Stack,
  Divider,
  Container,
  Typography,
  Grid,
  Skeleton,
  Switch,
  TextField,
  InputAdornment,
  DialogContent,
  DialogTitle,
} from '@mui/material';
// graphQL
import { GET_PRE_BOOKING_BY_ID } from '../../graphQL/queries';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { RESPONSE_PRE_BOOKING_REQUEST } from 'src/graphQL/mutations';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { DialogAnimate } from 'src/components/animate';
import UpdatePreBookingRefuseStatus from './UpdatePreBookingRefuseStatus';
// sections

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function PreBookingResponse() {
  const { id } = useParams();
  const { user } = useAuth();
  console.log({ user });
  const [switchChecked, setSwitchChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState();
  const [negotiated, setNegotiated] = useState();
  const [negotiatedPricePerWord, setNegotiatedPricePerWord] = useState(0);
  const [negotiatedDate, setNegotiatedDate] = useState();
  const [reason, setReason] = useState('');
  const [openRefuse, setOpenRefuse] = useState(false);
  // graphQL connection
  const { loading, error, data, refetch } = useQuery(GET_PRE_BOOKING_BY_ID, {
    variables: {
      getPreBookingByIdId: id,
    },
  });
  const [editPreBookingRequest, { loading: loadingEdit }] = useMutation(RESPONSE_PRE_BOOKING_REQUEST);
  useEffect(() => {
    if (data) {
      setBooking(data?.getPreBookingByID);
    }
  }, [data]);
  // filter functions
  const onAccept = async () => {
    console.log('accept');
    await editPreBookingRequest({
      variables: {
        preBookingDetails: {
          acceptedDateTime: new Date().toISOString(),
          linguistId: user?.id,
          name: user?.name,
          email: user?.email,
          status: 'Accepted',
          total: booking?.total,
          pricePerWord: booking?.pricePerWord,

          reason: reason,
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();
      enqueueSnackbar('Accepted');
    });
  };
  const onRefuse = async (reason) => {
    await editPreBookingRequest({
      variables: {
        preBookingDetails: {
          status: 'Refused',
          acceptedDateTime: new Date().toISOString(),
          linguistId: user?.id,
          name: user?.name,
          email: user?.email,
          total: booking?.total,
          pricePerWord: booking?.pricePerWord,
          reason: reason,
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();
      // setBooking((pre) => ({ ...pre, status: 'Refused' }));
      enqueueSnackbar('Refused', { variant: 'error' });
    });
  };
  const onNegotiate = async () => {
    if (
      !negotiated ||
      negotiated === 0 ||
      (booking?.projectType === 'Barzano' && negotiatedPricePerWord === 0) ||
      (booking?.projectType === 'Barzano' && !negotiatedPricePerWord)
    ) {
      enqueueSnackbar('Add negotiated value', { variant: 'error' });
    } else {
      await editPreBookingRequest({
        variables: {
          preBookingDetails: {
            status: 'Negotiated',
            // acceptedDateTime: new Date().toISOString(),
            linguistId: user?.id,
            name: user?.name,
            email: user?.email,
            pricePerWord: negotiatedPricePerWord,
            total: negotiated,
            reason: reason,
          },
          id: booking?._id,
        },
      }).then(() => {
        refetch();
        enqueueSnackbar('Negotiated', { variant: 'error' });
      });
    }
  };
  if (error) return `Error! ${error.message}`;
  if (loading) return <Skeleton height={380} width={'100%'} />;
  return (
    <Page title="Linguist Pre-booking Request">
      <RootStyle>
        {booking?.projectType === 'Interpretation' ? (
          <Container>
            <Typography variant="h3" align="center" paragraph>
              New Opportunity - Interpretation Project
            </Typography>
            <Typography align="center" sx={{ color: 'text.secondary' }}></Typography>
            {booking?.status === 'Pending' ? (
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid container sx={{ p: 3, maxWidth: 600 }} spacing={4} justifyContent="center">
                  <Grid align="center" item xs={12}>
                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: booking?.description }} />
                  </Grid>

                  <Divider style={{ width: '60%' }} sx={{ mt: 2.5, mb: 1.5 }} />
                  <Grid item xs={12} md={4} lg={8}>
                    <>
                      <Typography sx={{ float: 'left', color: 'text.secondary' }} variant="body2">
                        Not happy with the rate proposed? Negotiate!
                      </Typography>
                      <Switch
                        sx={{ float: 'right' }}
                        onChange={() => setSwitchChecked(!switchChecked)}
                        checked={switchChecked}
                      />
                    </>
                  </Grid>
                  <Grid item xs={8} md={4} lg={8}>
                    {switchChecked === true ? (
                      <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                        <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Total"
                            onChange={(event) => setNegotiated(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="string"
                            name="reason"
                            label="Reason"
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            minRows={2}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                        </Stack>
                        <LoadingButton
                          onClick={() => onNegotiate()}
                          variant="contained"
                          size="large"
                          loading={loadingEdit}
                          sx={{ maxWidth: { md: 320 } }}
                        >
                          {'Negotiate'}
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 1 }}
                        mt={2}
                      >
                        <LoadingButton
                          loading={loadingEdit}
                          variant="contained"
                          size="large"
                          color="error"
                          onClick={() => setOpenRefuse(true)}
                        >
                          Maybe next time!
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          sx={{ color: 'white' }}
                          size="large"
                          color="success"
                          loading={loadingEdit}
                          onClick={() => onAccept()}
                        >
                          Apply
                        </LoadingButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : booking?.status === 'Accepted' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD{' '}
                </Typography>
              </Stack>
            ) : booking?.status === 'Refused' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR YOUR RESPONSE; WE RESPECT YOUR DECISION AND HOPE TO WORK TOGETHER IN THE FUTURE.
                </Typography>
              </Stack>
            ) : booking?.status === 'Negotiated' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD
                </Typography>
              </Stack>
            ) : null}
          </Container>
        ) : booking?.projectType === 'Barzano' ? (
          <Container>
            <Typography variant="h3" align="center" paragraph>
              New Opportunity - Barzano Project{' '}
            </Typography>
            <Typography align="center" sx={{ color: 'text.secondary' }}></Typography>
            {booking?.status === 'Pending' ? (
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid container sx={{ p: 3, maxWidth: 600 }} spacing={4} justifyContent="center">
                  <Grid align="center" item xs={12}>
                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: booking?.description }} />
                  </Grid>

                  <Divider style={{ width: '60%' }} sx={{ mt: 2.5, mb: 1.5 }} />
                  <Grid item xs={12} md={4} lg={8}>
                    <>
                      <Typography sx={{ float: 'left', color: 'text.secondary' }} variant="body2">
                        Not happy with the rate proposed? Negotiate!
                      </Typography>
                      <Switch
                        sx={{ float: 'right' }}
                        onChange={() => setSwitchChecked(!switchChecked)}
                        checked={switchChecked}
                      />
                    </>
                  </Grid>
                  <Grid item xs={8} md={4} lg={8}>
                    {switchChecked === true ? (
                      <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                        <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Price Per Word"
                            onChange={(event) => setNegotiatedPricePerWord(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Total"
                            onChange={(event) => setNegotiated(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="string"
                            name="reason"
                            label="Reason"
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            minRows={2}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                        </Stack>
                        <LoadingButton
                          onClick={() => onNegotiate()}
                          variant="contained"
                          size="large"
                          loading={loadingEdit}
                          sx={{ maxWidth: { md: 320 } }}
                        >
                          {'Negotiate'}
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 1 }}
                        mt={2}
                      >
                        <LoadingButton
                          loading={loadingEdit}
                          variant="contained"
                          size="large"
                          color="error"
                          onClick={() => setOpenRefuse(true)}
                        >
                          Maybe next time!
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          sx={{ color: 'white' }}
                          size="large"
                          color="success"
                          loading={loadingEdit}
                          onClick={() => onAccept()}
                        >
                          Apply
                        </LoadingButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : booking?.status === 'Accepted' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD{' '}
                </Typography>
              </Stack>
            ) : booking?.status === 'Refused' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR YOUR RESPONSE; WE RESPECT YOUR DECISION AND HOPE TO WORK TOGETHER IN THE FUTURE.
                </Typography>
              </Stack>
            ) : booking?.status === 'Negotiated' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD
                </Typography>
              </Stack>
            ) : null}
          </Container>
        ) : booking?.projectType === 'Translation' ? (
          <Container>
            <Typography variant="h3" align="center" paragraph>
              New Opportunity - Translation Project{' '}
            </Typography>
            <Typography align="center" sx={{ color: 'text.secondary' }}></Typography>
            {booking?.status === 'Pending' ? (
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid container sx={{ p: 3, maxWidth: 600 }} spacing={4} justifyContent="center">
                  <Grid align="center" item xs={12}>
                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: booking?.description }} />
                  </Grid>

                  <Divider style={{ width: '60%' }} sx={{ mt: 2.5, mb: 1.5 }} />
                  <Grid item xs={12} md={4} lg={8}>
                    <>
                      <Typography sx={{ float: 'left', color: 'text.secondary' }} variant="body2">
                        Not happy with the rate proposed? Negotiate!
                      </Typography>
                      <Switch
                        sx={{ float: 'right' }}
                        onChange={() => setSwitchChecked(!switchChecked)}
                        checked={switchChecked}
                      />
                    </>
                  </Grid>
                  <Grid item xs={8} md={4} lg={8}>
                    {switchChecked === true ? (
                      <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                        <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Price Per Word"
                            onChange={(event) => setNegotiatedPricePerWord(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Total"
                            onChange={(event) => setNegotiated(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="string"
                            name="reason"
                            label="Reason"
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            minRows={2}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                        </Stack>
                        <LoadingButton
                          onClick={() => onNegotiate()}
                          variant="contained"
                          size="large"
                          loading={loadingEdit}
                          sx={{ maxWidth: { md: 320 } }}
                        >
                          {'Negotiate'}
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 1 }}
                        mt={2}
                      >
                        <LoadingButton
                          loading={loadingEdit}
                          variant="contained"
                          size="large"
                          color="error"
                          onClick={() => setOpenRefuse(true)}
                        >
                          Maybe next time!
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          sx={{ color: 'white' }}
                          size="large"
                          color="success"
                          loading={loadingEdit}
                          onClick={() => onAccept()}
                        >
                          Apply
                        </LoadingButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : booking?.status === 'Accepted' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD
                </Typography>
              </Stack>
            ) : booking?.status === 'Refused' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR YOUR RESPONSE; WE RESPECT YOUR DECISION AND HOPE TO WORK TOGETHER IN THE FUTURE.
                </Typography>
              </Stack>
            ) : booking?.status === 'Negotiated' ? (
              <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
                <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                  THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE
                  TO MOVE FORWARD
                </Typography>
              </Stack>
            ) : null}
          </Container>
        ) : null}
      </RootStyle>
      <DialogAnimate sx={{ maxWidth: 220 }} open={openRefuse} onClose={() => setOpenRefuse(false)}>
        <DialogTitle>Reason for Rejection</DialogTitle>
        <DialogContent sx={{ typography: 'body2' }}>
          <UpdatePreBookingRefuseStatus
            handleCloseModal={() => setOpenRefuse(false)}
            onRefuse={onRefuse}
            loading={loadingEdit}
          />
        </DialogContent>
      </DialogAnimate>
    </Page>
  );
}

// ----------------------------------------------------------------------
