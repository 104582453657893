import { format, getTime, formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
// ----------------------------------------------------------------------
export function fIalianDate(date) {
  if (date) {
    return format(new Date(date), 'PPP', { locale: it });
  }
}
export function fItalinDateTime(date) {
  if (date) {
    return format(new Date(date),'PPPpp', { locale: it });
  }
}
export function fDate(date) {
  if (date) {
    return format(new Date(date), 'dd MMM yyyy');
  }
}
export function fDateString(date) {
  if (date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }
}

export function fDateTime(date) {
  if (date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  }
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm a');
}
export function fDayTime(date){
  return format(new Date(date), 'HH:mm');
}
export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
