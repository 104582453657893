// @mui
import { Card, Table, TableBody, CardHeader, Skeleton, TableCell, TableRow, Typography, Stack } from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import { BARZANO_YEARLY_SALES_REPORT,  } from 'src/graphQL/queries';
import { useQuery } from '@apollo/client';
import Scrollbar from 'src/components/Scrollbar';
import { fCurrency, fPercent } from 'src/utils/formatNumber';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------



export default function TotalSales({ title, subheader, ...other }) {
    const [year, setYear] = useState('2024')
    const { data: companySalesData, loading: companyTotalSalesLoading, error } = useQuery(BARZANO_YEARLY_SALES_REPORT, {
        variables: { year: year }, fetchPolicy: 'no-cache'
    })

    if (error) return `Error: ${error?.message}`

    return (
        <Card sx={{ padding: "30px" }}>
            <Typography  variant="h6">
                Total Sales
            </Typography>
            {companyTotalSalesLoading ? <Skeleton variant="rectangular" height={260} /> :
                <Stack sx={{ marginTop: "20px", height: "100%" }} spacing={2}>
                    <Typography variant="h3">{fCurrency(Number(companySalesData?.getBarzanoYearlySales?.currentYearSalesSum || 0))}</Typography>
                    <Typography variant="h6">
                        Previous Year Sales (YTD)
                    </Typography>
                    <Typography variant="h3">{fCurrency(companySalesData?.getBarzanoYearlySales?.previousYearSalesSum)}</Typography>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: 0.6 }}>
                        <Iconify
                            width={24}
                            height={24}
                            sx={{
                                color: ((Number(companySalesData?.getBarzanoYearlySales?.currentYearSalesSum) - Number(companySalesData?.getBarzanoYearlySales?.previousYearSalesSum)) * 100 / companySalesData?.getBarzanoYearlySales?.previousYearSalesSum) >= 0 ? 'green' : "red"
                            }}
                            icon={((Number(companySalesData?.getBarzanoYearlySales?.currentYearSalesSum) - Number(companySalesData?.getBarzanoYearlySales?.previousYearSalesSum)) * 100 / companySalesData?.getBarzanoYearlySales?.previousYearSalesSum) >= 0 ? 'solar:double-alt-arrow-up-bold-duotone' : 'solar:double-alt-arrow-down-bold-duotone'} />

                        <Typography variant="subtitle2" component="span" sx={{ ml: 0.5 }}>
                            {((Number(companySalesData?.getBarzanoYearlySales?.currentYearSalesSum) - Number(companySalesData?.getBarzanoYearlySales?.previousYearSalesSum)) * 100 / companySalesData?.getBarzanoYearlySales?.previousYearSalesSum) > 0 && '+'}
                            {fPercent(((Number(companySalesData?.getBarzanoYearlySales?.currentYearSalesSum) - Number(companySalesData?.getBarzanoYearlySales?.previousYearSalesSum)) * 100 / companySalesData?.getBarzanoYearlySales?.previousYearSalesSum))}  &nbsp;than last year
                        </Typography>
                    </Stack>

                </Stack>}
        </Card>
    );
}
