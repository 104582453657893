// @mui
import { Card, Table, TableBody, CardHeader, Skeleton, TableCell, TableRow } from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import { GET_BARZANO_MONTLY_SALES_REPORT } from 'src/graphQL/queries';
import { useQuery } from '@apollo/client';
import Scrollbar from 'src/components/Scrollbar';
import { fCurrency, fPercent } from 'src/utils/formatNumber';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import { sum } from 'lodash';
// ----------------------------------------------------------------------

const TABLE_HEAD = [

  { id: '', label: 'Month', align: 'center' },
  { id: '', label: `${new Date().getFullYear() - 1}`, align: 'center' },
  { id: '', label: `${new Date().getFullYear()}`, align: 'center' },
  { id: '', label: '%', align: 'center' },

];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export default function MonthlySales({ title, subheader, ...other }) {
  const theme = useTheme();

  const { data, loading, error } = useQuery(GET_BARZANO_MONTLY_SALES_REPORT,
    { fetchPolicy: 'no-cache' })
  if (error) return `Error: ${error?.message}`

  return (
    <>
      <Card {...other} >
        <CardHeader
          title={title}
          sx={{ mb: 3 }}
        />
        {loading ? <Skeleton height={300} /> :
          <Scrollbar>
            <Table  >
              <TableHeadCustom headLabel={TABLE_HEAD}
                sx={{
                  '& .MuiTableCell-head': {
                    bgcolor: '#3be058',
                    color: '#ffff',
                  },
                  '&.MuiTableHead-root': {
                    padding: 0,
                  },
                }}

              />
              <TableBody>
                {data?.barzanoMonthlySalesReport?.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
                  <StyledTableRow>
      <StyledTableCell align='center' style={{
        fontWeight:'bold'
      }}>Total</StyledTableCell>
      <StyledTableCell align='center' style={{
        fontWeight:'bold'
      }}>{fCurrency(sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) )}</StyledTableCell>
      <StyledTableCell align='center' style={{
        fontWeight:'bold'
      }}>{fCurrency(sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) ) }</StyledTableCell>
      <StyledTableCell align="center"
        sx={{
          color: (sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100 > 0 ? theme.palette.success.dark : theme.palette.error.main,
          fontWeight: 'bold'
        }}
      >
        {(sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100  !== -100 && (sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100  > 0 ? "+" : ''}
        {(sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100  !== -100 && (sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
          sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100  ? `${fPercent((sum(data?.barzanoMonthlySalesReport?.map(item=>item?.currentYearTotal)) - 
            sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal))) / 
            sum(data?.barzanoMonthlySalesReport?.map(item=>item?.previousYearsTotal)) * 100 )}` : '-'}

      </StyledTableCell>
    </StyledTableRow>
              </TableBody>
            </Table>
          </Scrollbar>}
      </Card>
    </>
  );
}
function Row({ row }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();
  let percentValue = (row?.currentYearTotal - row?.previousYearsTotal) / row?.previousYearsTotal * 100
  return (
    <StyledTableRow>
      <StyledTableCell align='center'>{row?.month}</StyledTableCell>
      <StyledTableCell align='center'>{fCurrency(row?.previousYearsTotal)}</StyledTableCell>
      <StyledTableCell align='center'>{row?.currentYearTotal > 0 ? fCurrency(row?.currentYearTotal) : '-'}</StyledTableCell>
      <StyledTableCell align="center"
        sx={{
          color: percentValue > 0 ? theme.palette.success.dark : theme.palette.error.main,
          fontWeight: 'bold'
        }}
      >
        {percentValue !== -100 && percentValue > 0 ? "+" : ''}
        {percentValue !== -100 && percentValue ? `${fPercent(percentValue)}` : '-'}

      </StyledTableCell>
    </StyledTableRow>
  );
}