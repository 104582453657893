import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_CLIENT, PATH_DASHBOARD, PATH_LINGUIST } from '../routes/paths';

// ----------------------------------------------------------------------

UserGuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function UserGuestGuard({ children, userType }) {
  const { isAuthenticated, user, type } = useAuth();

  const userHasRequiredType = type === userType ? true : false;
  if (isAuthenticated && userHasRequiredType) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }
  // else if (isAuthenticated && user?.type === "Linguist") {
  //   return <Navigate to={PATH_LINGUIST.root} />;
  // }
  return <>{children}</>;
}
export function LinguistGuestGuard({ children, userType }) {
  const { isAuthenticated, user, type } = useAuth();
  if (isAuthenticated && user?.type === "Linguist") {

    if (user?.profileStatus === "unverified") {
      return <Navigate to={PATH_LINGUIST.user.verifyEmail} />;
    }
   if(Number(user?.profileCompletion)<=80){
    return <Navigate to={PATH_LINGUIST.user.editProfile} />;
   }
    return <Navigate to={PATH_LINGUIST.user.translationProjects} />;
  }
  else if (isAuthenticated && user?.type === "Technician") {

    return <Navigate to={PATH_LINGUIST.technician.technicianSheetsList} />;
  } 
   else if (isAuthenticated && user?.type === "Formatter") {

    return <Navigate to={PATH_LINGUIST.formatter.formattingFilesList} />;
  }else if (isAuthenticated && user?.type === "Marketeer"){
    return <Navigate to={PATH_LINGUIST.marketeer.marketingChannelList} />;
  }
  return <>{children}</>;
}
export function ClientGuestGuard({ children, userType }) {
  const { isAuthenticated, user, type } = useAuth();
  // Call debugger to see what is happening after console
  // console.log("isAuthenticated", isAuthenticated, "user", user, "type", type);
  // const userHasRequiredType = user?.type === "userType" ? true : false;
  if (isAuthenticated && user?.type === "Client") {

    // if (user?.profileStatus === "unverified") {
    //   return <Navigate to={PATH_LINGUIST.user.verifyEmail} />;

    // }
    return <Navigate to={PATH_CLIENT.client.dashboard} />;
  }

  return <>{children}</>;
}
