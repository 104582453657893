import * as Yup from 'yup';
import { useMutation, gql } from '@apollo/client';
import { useState, useRef  } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
 
import { LoadingButton } from '@mui/lab';

// graphql 
import { CLIENT_LOGIN } from '../../../../graphQL/mutations'
// routes
import { PATH_AUTH_CLIENT, PATH_CLIENT } from '../../../../routes/paths';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef'; 
// components 
import Iconify from '../../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
// ----------------------------------------------------------------------

export default function LoginForm({setEmail, setIsVerified }) {
  const { login } = useAuth();
  let recaptcha_keys = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const captchaRef = useRef(null)

  const [clientUsers, { data, error, loading }] = useMutation(CLIENT_LOGIN);

  // const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false)
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("L'email deve essere un indirizzo email valido").required("L'email è obbligatoria"),
    password: Yup.string().required('La password è obbligatoria'),
    wrongPassword: Yup.number(),
    captchaToken: Yup.string().when('wrongPassword', {
      is: (val) => val > 0,
      then: Yup.string().required('Il Captcha è obbligatorio').nullable()
    }),
  });

  const defaultValues = {
    remember: true,
    wrongPassword: 0,
    captchaToken: ""
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset, control,
    setError,
    handleSubmit, watch, setValue,
    formState: { errors, isSubmitting },
  } = methods;
  const navigate = useNavigate()
  const values = watch();

  const onSubmit = async (data) => {
    
    try {
      await clientUsers({ variables: { email: data.email, password: data.password, recaptcha: data.captchaToken } })
        .then((res) => {
          console.log(res)
          if(res?.data?.login){
            // setSuccess(true)
            localStorage.setItem("lang", res?.data?.login?.lang || 'it')
            captchaRef.current.reset();
            console.log(res?.data?.login?.isVerified)
            if(res?.data?.login?.isVerified){
              window.location.href = `${PATH_CLIENT.root}?token=${res?.data?.login?.token}`
            }else{
              setEmail(data.email)
              setIsVerified(false)
              
            }
          }
          // login(res.data.login)
          

        })
        .catch((err) => {
          setValue("wrongPassword", values.wrongPassword + 1);
          captchaRef.current.reset();
        });
    } catch (error) {
      console.error(error);

    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error && <Alert severity="error">Inserisci una password valida</Alert>}
        {success && <Alert severity="success">Ti verrà inviata un'e-mail per la verifica</Alert>}
        <RHFTextField name="email" label="Indirizzo e-mail" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {values.wrongPassword > 0 && !success &&
          <Controller
            name="captchaToken"
            control={control}
            render={({ field }) => (
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={recaptcha_keys}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />}
        {errors?.captchaToken && <Alert severity="error">{errors?.captchaToken?.message}</Alert>}
   
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH_CLIENT.resetPassword}>
        Ha dimenticato la password?
        </Link>
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
