// routes
import { PATH_CLIENT, PATH_DASHBOARD, PATH_LINGUIST } from '../../../routes/paths';
// components

import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  company: getIcon('ic_company'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  ocr:getIcon('ic_ocr'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  brochure: getIcon('ic_brochure'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  management: getIcon("ic_management"),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  quality: getIcon('ic_label'),
  vault: getIcon('ic_folder'),
  file:getIcon('ic_file'),
  document:getIcon('ic_file1'),
  task: getIcon('ic_task'),
  translationProject: getIcon('ic_translationProjects'),
  barzanoProject: getIcon('ic_barzanoProjects'),
  interpretationProject: getIcon('ic_interpretationProjects'),
  phone: getIcon('ic_phone'),
  videoConferenceProject: getIcon('ic_videoConferenceProjects'),
  analytics: getIcon("ic_analytics"),
  issue: getIcon("ic_chat"),
  proofReading:getIcon("ic_proof_reading"),
  job:getIcon("ic_job")
};

const navConfig = [

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'Project Manager',
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'Finance Manager',
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'Account Manager',
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'admin',
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'Senior Manager',
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: 'Marketing Manager',
      },

      {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'admin',
        children: [
          { title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'admin' },
          { title: 'create', role: 'admin', path: PATH_DASHBOARD.user.new, roles: 'admin' },
          { title: 'account', path: PATH_DASHBOARD.user.account, roles: 'admin' },
        ],
      },

      {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'Senior Manager',
        children: [
          { title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'Senior Manager' },
          { title: 'create', role: 'Senior Manager', path: PATH_DASHBOARD.user.new, roles: 'Senior Manager' },
          { title: 'account', path: PATH_DASHBOARD.user.account, roles: 'Senior Manager' },
        ],
      },
      {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'Project Manager',
        children: [{ title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'Project Manager' }],
      }, 
        {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'Finance Manager',
        children: [{ title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'Finance Manager' }],
      },
      {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'Account Manager',
        children: [{ title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'Account Manager' }],
      },
      {
        title: 'team',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: 'Marketing Manager',
        children: [{ title: 'cards', path: PATH_DASHBOARD.user.cards, roles: 'Marketing Manager' }],
      },
      {
        title: 'tasks',
        icon: ICONS.kanban,
        path: PATH_DASHBOARD.task.list,
        roles: 'Project Manager',
      },  
         {
        title: 'tasks',
        icon: ICONS.kanban,
        path: PATH_DASHBOARD.task.list,
        roles: 'Finance Manager',
      },
      {
        title: 'tasks',
        icon: ICONS.kanban,
        path: PATH_DASHBOARD.task.list,
        roles: 'admin',
      },
          {
        title: 'tasks',
        icon: ICONS.kanban,
        path: PATH_DASHBOARD.task.list,
        roles: 'Senior Manager',
      },
      {
        title: 'tasks',
        icon: ICONS.kanban,
        path: PATH_DASHBOARD.task.list,
        roles: 'Marketing Manager',
      },
      // ISSUES
      {
        title: 'Quality Cases',
        icon: ICONS.quality,
        path: PATH_DASHBOARD.issue.root,
        roles: 'admin',
        children: [
          { title: 'list', path: PATH_DASHBOARD.issue.list },
          { title: 'create', path: PATH_DASHBOARD.issue.new },
        ],
      },
        {
        title: 'Quality Cases',
        icon: ICONS.quality,
        path: PATH_DASHBOARD.issue.root,
        roles: 'Senior Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.issue.list },
          { title: 'create', path: PATH_DASHBOARD.issue.new },
        ],
      },
      {
        title: 'Quality Cases',
        icon: ICONS.quality,
        path: PATH_DASHBOARD.issue.root,
        roles: 'Project Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.issue.list },
          { title: 'create', path: PATH_DASHBOARD.issue.new },
        ],
      },   
        {
        title: 'Quality Cases',
        icon: ICONS.quality,
        path: PATH_DASHBOARD.issue.root,
        roles: 'Finance Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.issue.list },
          { title: 'create', path: PATH_DASHBOARD.issue.new },
        ],
      },
      {
        title: 'Quality Cases',
        icon: ICONS.quality,
        path: PATH_DASHBOARD.issue.root,
        roles: 'Account Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.issue.list },
          { title: 'create', path: PATH_DASHBOARD.issue.new },
        ],
      },
      // COMPANY
      {
        title: 'clients',
        icon: ICONS.banking,
        path: PATH_DASHBOARD.company.root,
        roles: 'Account Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.company.list },
          { title: 'create', path: PATH_DASHBOARD.company.new },
        ],
      },
      {
        title: 'clients',
        icon: ICONS.banking,
        path: PATH_DASHBOARD.company.root,
        roles: 'admin',
        children: [
          { title: 'list', path: PATH_DASHBOARD.company.list },
          { title: 'create', path: PATH_DASHBOARD.company.new },
        ],
      },
           {
        title: 'clients',
        icon: ICONS.banking,
        path: PATH_DASHBOARD.company.root,
        roles: 'Senior Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.company.list },
          { title: 'create', path: PATH_DASHBOARD.company.new },
        ],
      },
      {
        title: 'clients',
        icon: ICONS.banking,
        path: PATH_DASHBOARD.company.root,
        roles: 'Project Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.company.list },
          { title: 'create', path: PATH_DASHBOARD.company.new },
        ],
      }, 
           {
        title: 'clients',
        icon: ICONS.banking,
        path: PATH_DASHBOARD.company.root,
        roles: 'Finance Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.company.list },
          { title: 'create', path: PATH_DASHBOARD.company.new },
        ],
      },
      // LINGUIST
      {
        title: 'Vendors',
        icon: ICONS.job,
        path: PATH_DASHBOARD.vendor.root,
        roles: 'Project Manager',
        children: [
          { title: 'Translators', path: PATH_DASHBOARD.vendor.translator },
          { title: 'Interpreters ', path: PATH_DASHBOARD.vendor.interpreter },
          { title: 'Technicians', path: PATH_DASHBOARD.vendor.technician },
          { title: 'Blacklisted', path: PATH_DASHBOARD.vendor.blacklisted },
          { title: 'Incomplete Profile', path: PATH_DASHBOARD.vendor.incomplete },
        ],
      },  
         {
        title: 'Vendors',
        icon: ICONS.job,
        path: PATH_DASHBOARD.vendor.root,
        roles: 'Finance Manager',
        children: [
          { title: 'Translators', path: PATH_DASHBOARD.vendor.translator },
          { title: 'Interpreters ', path: PATH_DASHBOARD.vendor.interpreter },
          { title: 'Technicians', path: PATH_DASHBOARD.vendor.technician },
          { title: 'Blacklisted', path: PATH_DASHBOARD.vendor.blacklisted },
          { title: 'Incomplete Profile', path: PATH_DASHBOARD.vendor.incomplete },
        ],
      },
      {
        title: 'Vendors',
        icon: ICONS.job,
        path: PATH_DASHBOARD.vendor.root,
        roles: 'admin',
        children: [
          { title: 'Translators', path: PATH_DASHBOARD.vendor.translator },
          { title: 'Interpreters ', path: PATH_DASHBOARD.vendor.interpreter },
          { title: 'Technicians', path: PATH_DASHBOARD.vendor.technician },
          { title: 'Blacklisted', path: PATH_DASHBOARD.vendor.blacklisted },
          { title: 'Incomplete Profile', path: PATH_DASHBOARD.vendor.incomplete },
        ],
      },
            {
        title: 'Vendors',
        icon: ICONS.job,
        path: PATH_DASHBOARD.vendor.root,
        roles: 'Senior Manager',
        children: [
          { title: 'Translators', path: PATH_DASHBOARD.vendor.translator },
          { title: 'Interpreters ', path: PATH_DASHBOARD.vendor.interpreter },
          { title: 'Technicians', path: PATH_DASHBOARD.vendor.technician },
          { title: 'Blacklisted', path: PATH_DASHBOARD.vendor.blacklisted },
          { title: 'Incomplete Profile', path: PATH_DASHBOARD.vendor.incomplete },
        ],
      },
      {
        title: 'Vendors',
        icon: ICONS.job,
        path: PATH_DASHBOARD.vendor.root,
        roles: 'Account Manager',
        children: [
          { title: 'Translators', path: PATH_DASHBOARD.vendor.translator },
          { title: 'Interpreters ', path: PATH_DASHBOARD.vendor.interpreter },
          { title: 'Technicians', path: PATH_DASHBOARD.vendor.technician },
          { title: 'Blacklisted', path: PATH_DASHBOARD.vendor.blacklisted },
          { title: 'Incomplete Profile', path: PATH_DASHBOARD.vendor.incomplete },
        ],
      },
          // Expenses 
          {
            title: 'Expenses',
            icon: ICONS.cart,
            path: PATH_DASHBOARD.expense.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.expense.list },
              { title: 'create', path: PATH_DASHBOARD.expense.new },
            ],
          },
          {
            title: 'Expenses',
            icon: ICONS.cart,
            path: PATH_DASHBOARD.expense.root,
            roles: 'Finance Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.expense.list },
              { title: 'create', path: PATH_DASHBOARD.expense.new },
            ],
          },
          // {
          //   title: 'Expenses',
          //   icon: ICONS.cart,
          //   path: PATH_DASHBOARD.expense.root,
          //   roles: 'Account Manager',
          //   children: [
          //     { title: 'list', path: PATH_DASHBOARD.expense.list },
          //     { title: 'create', path: PATH_DASHBOARD.expense.new },
          //   ],
          // },
            // Invoice
      {
        title: 'Invoices',
        icon: ICONS.invoice,
        path: PATH_DASHBOARD.invoice.root,
        roles: 'Project Manager',
        children: [
          { title: 'Client', path: PATH_DASHBOARD.invoice.listClientInvoice },
          { title: 'Vendor', path: PATH_DASHBOARD.invoice.list },
        ],
      },  
        {
        title: 'Invoices',
        icon: ICONS.invoice,
        path: PATH_DASHBOARD.invoice.root,
        roles: 'Finance Manager',
        children: [
          { title: 'Client', path: PATH_DASHBOARD.invoice.listClientInvoice },
          { title: 'Vendor', path: PATH_DASHBOARD.invoice.list },
        ],
      },
      {
        title: 'Invoices',
        icon: ICONS.invoice,
        path: PATH_DASHBOARD.invoice.root,
        roles: 'admin',
        children: [          
          { title: 'Client', path: PATH_DASHBOARD.invoice.listClientInvoice },
          { title: 'Vendor', path: PATH_DASHBOARD.invoice.list },

        ],
      },
            {
        title: 'Invoices',
        icon: ICONS.invoice,
        path: PATH_DASHBOARD.invoice.root,
        roles: 'Senior Manager',
        children: [          
          { title: 'Client', path: PATH_DASHBOARD.invoice.listClientInvoice },
          { title: 'Vendor', path: PATH_DASHBOARD.invoice.list },

        ],
      },
      {
        title: 'Invoices',
        icon: ICONS.invoice,
        path: PATH_DASHBOARD.invoice.root,
        roles: 'Account Manager',
        children: [
          { title: 'Client', path: PATH_DASHBOARD.invoice.listClientInvoice },
          { title: 'Vendor', path: PATH_DASHBOARD.invoice.list },
        ],
      },
     
      {
        title: 'Brochures',
        icon: ICONS.blog,
        path: PATH_DASHBOARD.brochure.root,
        roles: 'admin',
        children: [
          { title: 'list', path: PATH_DASHBOARD.brochure.list },
          { title: 'create', path: PATH_DASHBOARD.brochure.new },
        ],
      },
      {
        title: 'Brochures',
        icon: ICONS.blog,
        path: PATH_DASHBOARD.brochure.root,
        roles: 'Senior Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.brochure.list },
          { title: 'create', path: PATH_DASHBOARD.brochure.new },
        ],
      },
      {
        title: 'Brochures',
        icon: ICONS.blog,
        path: PATH_DASHBOARD.brochure.root,
        roles: 'Account Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.brochure.list },
          { title: 'create', path: PATH_DASHBOARD.brochure.new },
        ],
      },
   
      // BARZANO PROJECT

      {
        title: 'Barzanò & Zanardo',
        icon: ICONS.translationProject,
        path: PATH_DASHBOARD.barzanoProject.root,
        roles: 'Project Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.barzanoProject.list },
          { title: 'create', path: PATH_DASHBOARD.barzanoProject.new },
          // {
          //   title: 'ocr',
          //   path: PATH_DASHBOARD.barzanoProject.ocr,
          // },
        ],
      },  
          {
        title: 'Barzanò & Zanardo',
        icon: ICONS.translationProject,
        path: PATH_DASHBOARD.barzanoProject.root,
        roles: 'Finance Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.barzanoProject.list },
          { title: 'create', path: PATH_DASHBOARD.barzanoProject.new },
          {
            title: 'ocr',
            path: PATH_DASHBOARD.barzanoProject.ocr,
          },
        ],
      },
      // TRANSLATION PROJECT
      {
        title: 'lingoYou',
        icon: ICONS.file,
        path: PATH_DASHBOARD.translationProject.root,
        roles: 'Project Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.translationProject.list },
          { title: 'create', path: PATH_DASHBOARD.translationProject.new },
        ],
      },   
         {
        title: 'lingoYou',
        icon: ICONS.file,
        path: PATH_DASHBOARD.translationProject.root,
        roles: 'Finance Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.translationProject.list },
          { title: 'create', path: PATH_DASHBOARD.translationProject.new },
        ],
      },
      {
        title: 'lingoYou',
        icon: ICONS.file,
        path: PATH_DASHBOARD.translationQuote.root,
        roles: 'Account Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.translationQuote.list },
          { title: 'create', path: PATH_DASHBOARD.translationQuote.new },
        ],
      },
      {
        title: 'congressi internazionali',
        icon: ICONS.booking,
        path: PATH_DASHBOARD.interpretationProject.root,
        roles: 'Project Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.interpretationProject.list },
          { title: 'create', path: PATH_DASHBOARD.interpretationProject.new },
          { title: 'Inventory', path: PATH_DASHBOARD.inventory.list },
        ],
      },
            {
        title: 'congressi internazionali',
        icon: ICONS.booking,
        path: PATH_DASHBOARD.interpretationProject.root,
        roles: 'Finance Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.interpretationProject.list },
          { title: 'create', path: PATH_DASHBOARD.interpretationProject.new },
        ],
      },
      {
        title: 'congressi internazionali',
        icon: ICONS.booking,
        path: PATH_DASHBOARD.interpretationQuote.root,
        roles: 'Account Manager',
        children: [
          { title: 'list', path: PATH_DASHBOARD.interpretationQuote.list },
          { title: 'create', path: PATH_DASHBOARD.interpretationQuote.new },
        ],
      },
      {
        title: 'Quotes',
        path: '#1',
        roles: 'admin',
        icon: ICONS.invoice,
        children: [

          {
            title: 'congressi internazionali',
            icon: ICONS.interpretationProject,
            path: PATH_DASHBOARD.interpretationQuote.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.interpretationQuote.list },
              { title: 'create', path: PATH_DASHBOARD.interpretationQuote.new },
            ],
          },
          {
            title: 'lingoYou',
            icon: ICONS.translationProject,
            path: PATH_DASHBOARD.translationQuote.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.translationQuote.list },
              { title: 'create', path: PATH_DASHBOARD.translationQuote.new },

            ],
          },
         
        ],
      },

       {
        title: 'Quotes',
        path: '#1',
        roles: 'Senior Manager',
        icon: ICONS.invoice,
        children: [

          {
            title: 'congressi internazionali',
            icon: ICONS.interpretationProject,
            path: PATH_DASHBOARD.interpretationQuote.root,
            roles: 'Senior Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.interpretationQuote.list },
              { title: 'create', path: PATH_DASHBOARD.interpretationQuote.new },
            ],
          },
          {
            title: 'lingoYou',
            icon: ICONS.translationProject,
            path: PATH_DASHBOARD.translationQuote.root,
            roles: 'Senior Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.translationQuote.list },
              { title: 'create', path: PATH_DASHBOARD.translationQuote.new },

            ],
          },
         
        ],
      },
      {
        title: 'Projects',
        path: '#1',
        roles: 'admin',
        icon: ICONS.document,
        children: [
          {
            title: 'Barzanò & Zanardo',
            icon: ICONS.barzanoProject,
            path: PATH_DASHBOARD.barzanoProject.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.barzanoProject.list },
              { title: 'create', path: PATH_DASHBOARD.barzanoProject.new },
              {
                title: 'ocr',
                path: PATH_DASHBOARD.barzanoProject.ocr,
              },
            ],
          },
          {
            title: 'lingoYou',
            icon: ICONS.translationProject,
            path: PATH_DASHBOARD.translationProject.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.translationProject.list },
              { title: 'create', path: PATH_DASHBOARD.translationProject.new },
            ],
          },
          {
            title: 'congressi internazionali',
            icon: ICONS.interpretationProject,
            path: PATH_DASHBOARD.interpretationProject.root,
            roles: 'admin',
            children: [
              { title: 'list', path: PATH_DASHBOARD.interpretationProject.list },
              { title: 'create', path: PATH_DASHBOARD.interpretationProject.new },
              { title: 'Inventory', path: PATH_DASHBOARD.inventory.list },
            ],
          },

          {
            title: 'calendar',
            icon: ICONS.calendar,
            path: PATH_DASHBOARD.projectsCalendar,
            roles: 'admin',
          },
        ],
      },
       {
        title: 'Projects',
        path: '#1',
        roles: 'Senior Manager',
        icon: ICONS.document,
        children: [
          {
            title: 'Barzanò & Zanardo',
            icon: ICONS.barzanoProject,
            path: PATH_DASHBOARD.barzanoProject.root,
            roles: 'Senior Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.barzanoProject.list },
              { title: 'create', path: PATH_DASHBOARD.barzanoProject.new },
              {
                title: 'ocr',
                path: PATH_DASHBOARD.barzanoProject.ocr,
              },
            ],
          },
          {
            title: 'lingoYou',
            icon: ICONS.translationProject,
            path: PATH_DASHBOARD.translationProject.root,
            roles: 'Senior Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.translationProject.list },
              { title: 'create', path: PATH_DASHBOARD.translationProject.new },
            ],
          },
          {
            title: 'congressi internazionali',
            icon: ICONS.interpretationProject,
            path: PATH_DASHBOARD.interpretationProject.root,
            roles: 'Senior Manager',
            children: [
              { title: 'list', path: PATH_DASHBOARD.interpretationProject.list },
              { title: 'create', path: PATH_DASHBOARD.interpretationProject.new },
              { title: 'Inventory', path: PATH_DASHBOARD.inventory.list },
            ],
          },
          {
            title: 'calendar',
            icon: ICONS.calendar,
            path: PATH_DASHBOARD.projectsCalendar,
            roles: 'Senior Manager',
          },
        ],
      },
      //CALLS
      {
        title: 'Make A Call',
        icon: ICONS.phone,
        path: PATH_DASHBOARD.Calls.call,
        roles: 'admin',
        // children: [
        //   { title: 'report', path: PATH_DASHBOARD.Calls.record },
        //   { title: 'call', path: PATH_DASHBOARD.Calls.call },
        // ],
      },
          {
        title: 'Make A Call',
        icon: ICONS.phone,
        path: PATH_DASHBOARD.Calls.call,
        roles: 'Senior Manager',
        // children: [
        //   { title: 'report', path: PATH_DASHBOARD.Calls.record },
        //   { title: 'call', path: PATH_DASHBOARD.Calls.call },
        // ],
      },
      {
        title: 'Make A Call',
        icon: ICONS.phone,
        path: PATH_DASHBOARD.Calls.call,
        roles: 'Project Manager',
        // children: [
        //   { title: 'report', path: PATH_DASHBOARD.Calls.record },
        //   { title: 'call', path: PATH_DASHBOARD.Calls.call },
        // ],
      },
           {
        title: 'Make A Call',
        icon: ICONS.phone,
        path: PATH_DASHBOARD.Calls.call,
        roles: 'Finance Manager',
        // children: [
        //   { title: 'report', path: PATH_DASHBOARD.Calls.record },
        //   { title: 'call', path: PATH_DASHBOARD.Calls.call },
        // ],
      },
      {
        title: 'Make A Call',
        icon: ICONS.phone,
        path: PATH_DASHBOARD.Calls.call,
        roles: 'Account Manager',
        // children: [
        //   { title: 'report', path: PATH_DASHBOARD.Calls.record },
        //   { title: 'call', path: PATH_DASHBOARD.Calls.call },
        // ],
      },

      
      {
        title: 'projects calendar',
        icon: ICONS.calendar,
        path: PATH_DASHBOARD.calendar,
        roles: 'Project Manager',
      },
           {
        title: 'projects calendar',
        icon: ICONS.calendar,
        path: PATH_DASHBOARD.calendar,
        roles: 'Finance Manager',
      },
      {
        title: 'projects calendar',
        icon: ICONS.calendar,
        path: PATH_DASHBOARD.calendar,
        roles: 'Account Manager',
      },

      // {
      //   title: 'Barzano OCR',
      //   path: PATH_DASHBOARD.Ocr.report,
      //   icon: ICONS.ocr,
      //   roles: 'admin',
      // },
      // {
      //   title: 'Barzano OCR',
      //   path: PATH_DASHBOARD.Ocr.report,
      //   icon: ICONS.ocr,
      //   roles: 'Project Manager',
      // },
      {
        title: 'Vault',
        icon: ICONS.vault,
        path: PATH_DASHBOARD.vault,
        roles: 'Project Manager',
      },   
         {
        title: 'Vault',
        icon: ICONS.vault,
        path: PATH_DASHBOARD.vault,
        roles: 'Finance Manager',
      },
      {
        title: 'Vault',
        icon: ICONS.vault,
        path: PATH_DASHBOARD.vault,
        roles: 'admin',
      },
          {
        title: 'Vault',
        icon: ICONS.vault,
        path: PATH_DASHBOARD.vault,
        roles: 'Senior Manager',
      },
      // TASK
   
      {
        title: 'Reports',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.reports.root,
        roles: 'admin',
        children: [
          {title: 'Barzanò & Zanardo', path:PATH_DASHBOARD.reports.barzanoReports},
          { title: 'Calls', path: PATH_DASHBOARD.reports.callRecords },
          {title: 'General Orders List', path:PATH_DASHBOARD.reports.allGeneralProjectsList},
          { title: 'Marketing Channels Analysis', path: PATH_DASHBOARD.reports.marketingChannelList },
          { title: 'Quotes Conversion', path: PATH_DASHBOARD.reports.quotesConversionReports },
          { title: 'Quality Cases', path: PATH_DASHBOARD.reports.issuesReports },
          {title:'Revenue Analysis', path:PATH_DASHBOARD.reports.totalsalesReport},
          {title:'Expenses', path:PATH_DASHBOARD.reports.expenseReport}
        ],
      },
      {
        title: 'Management',
        icon: ICONS.management,
        path: PATH_DASHBOARD.management,
        roles: 'admin',
      },
      {
        title: 'Reports',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.reports.root,
        roles: 'Senior Manager',
        children: [
          {title: 'Barzanò & Zanardo', path:PATH_DASHBOARD.reports.barzanoReports},
          { title: 'Calls', path: PATH_DASHBOARD.reports.callRecords },
          {title: 'General Orders List', path:PATH_DASHBOARD.reports.allGeneralProjectsList},
          { title: 'Marketing Channels Analysis', path: PATH_DASHBOARD.reports.marketingChannelList },
          { title: 'Quotes Conversion', path: PATH_DASHBOARD.reports.quotesConversionReports },
          { title: 'Quality Cases', path: PATH_DASHBOARD.reports.issuesReports },
          {title:'Revenue Analysis', path:PATH_DASHBOARD.reports.totalsalesReport},
        ],
      },
      {
        title: 'Reports',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.reports.root,
        roles: 'Project Manager',
        children: [
          {title: 'Barzanò & Zanardo', path:PATH_DASHBOARD.reports.barzanoReports},
          { title: 'Calls', path: PATH_DASHBOARD.reports.callRecords },
          {title: 'General Orders List', path:PATH_DASHBOARD.reports.allGeneralProjectsList},
          { title: 'Marketing Channels Analysis', path: PATH_DASHBOARD.reports.marketingChannelList },
          { title: 'Quotes Conversion', path: PATH_DASHBOARD.reports.quotesConversionReports },
          { title: 'Quality Cases', path: PATH_DASHBOARD.reports.issuesReports },
          {title:'Revenue Analysis', path:PATH_DASHBOARD.reports.totalsalesReport},
        ],
      },
            {
        title: 'Reports',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.reports.root,
        roles: 'Finance Manager',
        children: [
          {title: 'Barzanò & Zanardo', path:PATH_DASHBOARD.reports.barzanoReports},
          { title: 'Calls', path: PATH_DASHBOARD.reports.callRecords },
          {title: 'General Orders List', path:PATH_DASHBOARD.reports.allGeneralProjectsList},
          { title: 'Marketing Channels Analysis', path: PATH_DASHBOARD.reports.marketingChannelList },
          { title: 'Quotes Conversion', path: PATH_DASHBOARD.reports.quotesConversionReports },
          { title: 'Quality Cases', path: PATH_DASHBOARD.reports.issuesReports },
          {title:'Revenue Analysis', path:PATH_DASHBOARD.reports.totalsalesReport},
          {title:'Expenses', path:PATH_DASHBOARD.reports.expenseReport}
        ],
      },
      {
        title: 'Reports',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.reports.root,
        roles: 'Account Manager',
        children: [
          {title: 'Barzanò & Zanardo', path:PATH_DASHBOARD.reports.barzanoReports},
          { title: 'Calls', path: PATH_DASHBOARD.reports.callRecords },
          {title: 'General Orders List', path:PATH_DASHBOARD.reports.allGeneralProjectsList},
          { title: 'Marketing Channels Analysis', path: PATH_DASHBOARD.reports.marketingChannelList },
          { title: 'Quotes Conversion', path: PATH_DASHBOARD.reports.quotesConversionReports },
          { title: 'Quality Cases', path: PATH_DASHBOARD.reports.issuesReports },
          {title:'Revenue Analysis', path:PATH_DASHBOARD.reports.totalsalesReport},
        ],
      },

      {
        title: 'Profile',
        icon: ICONS.user,
        path: PATH_LINGUIST.user.profile,
        roles: "Linguist",
      },
      {
        title: 'Services',
        icon: ICONS.task,
        path: PATH_LINGUIST.user.translation,
        roles: "Linguist",
      },
      // {
      //   title: 'Invoice',
      //   icon: ICONS.invoice,
      //   path: PATH_LINGUIST.user.invoice,
      //   roles: "Linguist",
      // },
      {
        title: 'Invoices',
        icon: ICONS.invoice,
        // path: PATH_LINGUIST.user.root,
        roles: 'Linguist',
        children: [
          { title: 'list', path: PATH_LINGUIST.user.invoice },
          { title: 'create', path: PATH_LINGUIST.user.invoiceNew },
        ],
      },
      {
        title: 'Translation Projects',
        icon: ICONS.translationProject,
        path: PATH_LINGUIST.user.translationProjects,
        roles: "Linguist",
      },
      {
      title: 'Interpretation Projects',
      icon: ICONS.interpretationProject,
      path: PATH_LINGUIST.user.interpretationProjects,
      roles: "Linguist",
    },
      {
        title: 'Proofreading Projects',
        icon: ICONS.proofReading,
        path: PATH_LINGUIST.user.prMenu,
        roles: "Linguist",
      },
      // {
      //   title: 'Invoices',
      //   icon: ICONS.invoice,
      //   path: PATH_LINGUIST.technician.root,
      //   roles: 'Technician',
      //   children: [
      //     { title: 'list', path: PATH_LINGUIST.technician.invoice },
      //     { title: 'create', path: PATH_LINGUIST.technician.invoiceNew },
      //   ],
      // },

      {
        title: 'Technician Sheets',
        icon: ICONS.task,
        path: PATH_LINGUIST.technician.technicianSheetsList,
        roles: "Technician",
      },
       {
        title: 'Inventory',
        icon: ICONS.ecommerce,
        path: PATH_LINGUIST.technician.inventory,
        roles: "Technician",
      },
      {
        title: 'Formatting Projects',
        icon: ICONS.task,
        path: PATH_LINGUIST.formatter.formattingFilesList,
        roles: "Formatter",
      },      {
        title: 'Projects Calendar',
        icon: ICONS.calendar,
        path: PATH_LINGUIST.formatter.projectsCalender,
        roles: "Formatter",
      },
      {
        title: 'Marketing Channels Analysis',
        icon: ICONS.task,
        path: PATH_LINGUIST.marketeer.marketingChannelList,
        roles: "Marketeer",
      },
      {
        title: 'Home',
        icon: ICONS.dashboard,
        path: PATH_CLIENT.client.dashboard,
        // path:"/clients/dashboard",
        roles: "Client",
      },
      // {
      //   title: 'Barzano Calender',
      //   icon: ICONS.dashboard,
      //   path: PATH_CLIENT.client.barzanoCalendar,
      //   roles: "Client",
      // },
      {
        title: 'Modifica profilo',
        icon: ICONS.user,
        path: PATH_CLIENT.client.editProfile,
        roles: "Client",
      },
      // {
      //   title: 'Preventivo immediato',
      //   icon: ICONS.translationProject,
      //   path: PATH_CLIENT.client.instantQuote,
      //   roles: "Client",
      // },
      {
        title: 'Ordini',
        icon: ICONS.translationProject,
        path: PATH_CLIENT.client.orders,
        roles: "Client",
      },
    ],
  },
];

export default navConfig;