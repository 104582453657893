import { Stack, Typography, Button, Link } from '@mui/material';

export default function PrivacyPolicy() {
  return (
    <Stack spacing={3} sx={{ marginTop: '20px', placeContent: 'center' }}>
      <Stack direction={{ xs: 'column' }} sx={{ placeItems: 'center' }} spacing={0}>
        <Stack
          spacing={2}
          sx={{
            margin: '20px',
            maxWidth: '80%',
          }}
        >
          <Typography variant="h1" sx={{ textAlign: 'center', color: 'blue' }}>
            Privacy Policy of LingoYou.com
          </Typography>

          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Privacy policy
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Owner and Data Controller
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            LINGOYOU GROUP S.R.L – Viale Fulvio Testi, 59 – 20092 CINISELL BALSAMO
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Owner contact email:<a href="mailto:info@lingoyou.com ">info@lingoyou.com </a>
            <br /> <br />
            Types of Data collected
          </Typography>

          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Among the types of Personal Data that this Application collects, by itself or through third parties, there
            are: Trackers; Usage Data.{' '}
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Complete details on each type of Personal Data collected are provided in the dedicated sections of this
            privacy policy or by specific explanation texts displayed prior to the Data collection. Personal Data may be
            freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.{' '}
            <br />
            Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this
            Data may make it impossible for this Application to provide its services. In cases where this Application
            specifically states that some Data is not mandatory, Users are free not to communicate this Data without
            consequences to the availability or the functioning of the Service. Users who are uncertain about which
            Personal Data is mandatory are welcome to contact the Owner. <br />
            Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this
            Data may make it impossible for this Application to provide its services. In cases where this Application
            specifically states that some Data is not mandatory, Users are free not to communicate this Data without
            consequences to the availability or the functioning of the Service. Users who are uncertain about which
            Personal Data is mandatory are welcome to contact the Owner. <br />
            <br />
            Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this
            Data may make it impossible for this Application to provide its services. In cases where this Application
            specifically states that some Data is not mandatory, Users are free not to communicate this Data without
            consequences to the availability or the functioning of the Service. Users who are uncertain about which
            Personal Data is mandatory are welcome to contact the Owner. <br />
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Mode and place of processing the Data
            <br /> <br />
            Methods of processing
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or
            unauthorized destruction of the Data. <br />
            The Data processing is carried out using computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases,
            the Data may be accessible to certain types of persons in charge, involved with the operation of this
            Application (administration, sales, marketing, legal, system administration) or external parties (such as
            third-party technical service providers, mail carriers, hosting providers, IT companies, communications
            agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be
            requested from the Owner at any time.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Legal basis of processing
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            The Owner may process Personal Data relating to Users if one of the following applies:
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            • Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner
            may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without
            having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever
            the processing of Personal Data is subject to European data protection law; <br />
            • provision of Data is necessary for the performance of an agreement with the User and/or for any
            pre-contractual obligations thereof; <br />
            • processing is necessary for compliance with a legal obligation to which the Owner is subject; <br />
            • processing is related to a task that is carried out in the public interest or in the exercise of official
            authority vested in the Owner; <br />
            • processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third
            party. <br />
            <br />
            In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing,
            and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
            requirement necessary to enter into a contract.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Place
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            The Data is processed at the Owner’s operating offices and in any other places where the parties involved in
            the processing are located.
            <br />
            <br />
            Depending on the User’s location, data transfers may involve transferring the User’s Data to a country other
            than their own. To find out more about the place of processing of such transferred Data, Users can check the
            section containing details about the processing of Personal Data.
            <br />
            <br />
            Users are also entitled to learn about the legal basis of Data transfers to a country outside the European
            Union or to any international organization governed by public international law or set up by two or more
            countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
            <br />
            <br />
            If any such transfer takes place, Users can find out more by checking the relevant sections of this document
            or inquire with the Owner using the information provided in the contact section.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Retention time
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Personal Data shall be processed and stored for as long as required by the purpose they have been collected
            for.
            <br />
            Therefore: <br />
            <br />
            • Personal Data collected for purposes related to the performance of a contract between the Owner and the
            User shall be retained until such contract has been fully performed. <br />
            • Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as
            needed to fulfill such purposes. Users may find specific information regarding the legitimate interests
            pursued by the Owner within the relevant sections of this document or by contacting the Owner. <br />
            <br />
            <br />
            The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to
            such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain
            Personal Data for a longer period whenever required to do so for the performance of a legal obligation or
            upon order of an authority. <br />
            <br />
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right
            to erasure, the right to rectification and the right to data portability cannot be enforced after expiration
            of the retention period.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            The purposes of processing
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal
            obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or
            third parties), detect any malicious or fraudulent activity, as well as the following: Analytics and
            Interaction with external social networks and platforms. <br />
            <br />
            For specific information about the Personal Data used for each purpose, the User may refer to the section
            “Detailed information on the processing of Personal Data”.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            The rights of Users
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Users may exercise certain rights regarding their Data processed by the Owner.
            <br />
            <br />
            In particular, Users have the right to do the following, to the extent permitted by law: <br />
            <br />
            <b>• Withdraw their consent at any time.</b> Users have the right to withdraw consent where they have
            previously given their consent to the processing of their Personal Data.
            <br />
            <b>• Object to processing of their Data.</b> Users have the right to object to the processing of their Data
            if the processing is carried out on a legal basis other than consent. Further details are provided in the
            dedicated section below.
            <br />
            <b>• Access their Data.</b> Users have the right to learn if Data is being processed by the Owner, obtain
            disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
            <br />
            <b>• Verify and seek rectification.</b> Users have the right to verify the accuracy of their Data and ask
            for it to be updated or corrected.
            <br />
            <b>• Restrict the processing of their Data.</b> Users have the right to restrict the processing of their
            Data. In this case, the Owner will not process their Data for any purpose other than storing it.
            <br />
            <b>• Have their Personal Data deleted or otherwise removed.</b> Users have the right to obtain the erasure
            of their Data from the Owner.
            <br />
            <b>• Receive their Data and have it transferred to another controller.</b> Users have the right to receive
            their Data in a structured, commonly used and machine readable format and, if technically feasible, to have
            it transmitted to another controller without any hindrance.
            <br />
            <b>• Lodge a complaint.</b> Users have the right to bring a claim before their competent data protection
            authority.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Details about the right to object to processing
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Where Personal Data is processed for a public interest, in the exercise of an official authority vested in
            the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such
            processing by providing a ground related to their particular situation to justify the objection.
            <br />
            <br />
            Users must know that, however, should their Personal Data be processed for direct marketing purposes, they
            can object to that processing at any time, free of charge and without providing any justification. Where the
            User objects to processing for direct marketing purposes, the Personal Data will no longer be processed for
            such purposes. To learn whether the Owner is processing Personal Data for direct marketing purposes, Users
            may refer to the relevant sections of this document.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            How to exercise these rights
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in
            this document. These requests can be exercised free of charge and will be answered by the Owner as early as
            possible and always within one month, providing Users with the information required by law. Any
            rectification or erasure of Personal Data or restriction of processing will be communicated by the Owner to
            each recipient, if any, to whom the Personal Data has been disclosed unless this proves impossible or
            involves disproportionate effort. At the Users’ request, the Owner will inform them about those recipients.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Cookie Policy
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            This Application uses Trackers.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Additional information about Data collection and processing
            <br />
            <br />
            Legal action
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            The User’s Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to
            possible legal action arising from improper use of this Application or the related Services.
            <br />
            The User declares to be aware that the Owner may be required to reveal personal data upon request of public
            authorities.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Additional information about User’s Personal Data
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            In addition to the information contained in this privacy policy, this Application may provide the User with
            additional and contextual information concerning particular Services or the collection and processing of
            Personal Data upon request.
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            System logs and maintenance
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            For operation and maintenance purposes, this Application and any third-party services may collect files that
            record interaction with this Application (System logs) or use other Personal Data (such as the IP Address)
            for this purpose.{' '}
          </Typography>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            Information not contained in this policy
          </Typography>
          <Typography
            variant="body"
            sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}
          >
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any
            time. Please see the contact information at the beginning of this document.{' '}
          </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          How “Do Not Track” requests are handled
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          This Application does not support “Do Not Track” requests. <br />
          To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read
          their privacy policies.{' '}
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Changes to this privacy policy
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this
          page and possibly within this Application and/or – as far as technically and legally feasible – sending a
          notice to Users via any contact information available to the Owner. It is strongly recommended to check this
          page often, referring to the date of the last modification listed at the bottom.
          <br />
          <br />
          Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall
          collect new consent from the User, where required.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Definitions and legal references
          <br />
          Personal Data (or Data)
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          Any information that directly, indirectly, or in connection with other information — including a personal
          identification number — allows for the identification or identifiability of a natural person.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Usage Data
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          Information collected automatically through this Application (or third-party services employed in this
          Application), which can include: the IP addresses or domain names of the computers utilized by the Users who
          use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
          utilized to submit the request to the server, the size of the file received in response, the numerical code
          indicating the status of the server’s answer (successful outcome, error, etc.), the country of origin, the
          features of the browser and the operating system utilized by the User, the various time details per visit
          (e.g., the time spent on each page within the Application) and the details about the path followed within the
          Application with special reference to the sequence of pages visited, and other parameters about the device
          operating system and/or the User’s IT environment.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          User
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Data Subject
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The natural person to whom the Personal Data refers.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Data Processor (or Data Supervisor)
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of
          the Controller, as described in this privacy policy.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Data Controller (or Owner)
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The natural or legal person, public authority, agency or other body which, alone or jointly with others,
          determines the purposes and means of the processing of Personal Data, including the security measures
          concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the
          Owner of this Application.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          This Application
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The means by which the Personal Data of the User is collected and processed.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Service
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The service provided by this Application as described in the relative terms (if available) and on this
          site/application.{' '}
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          European Union (or EU)
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          Unless otherwise specified, all references made within this document to the European Union include all current
          member states to the European Union and the European Economic Area.{' '}
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Cookie
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          Cookies are Trackers consisting of small sets of data stored in the User’s browser.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Tracker
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          Tracker indicates any technology – e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and
          fingerprinting – that enables the tracking of Users, for example by accessing or storing information on the
          User’s device.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Legal information
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of
          Regulation (EU) 2016/679 (General Data Protection Regulation).
          <br />
          <br />
          This privacy policy relates solely to this Application, if not stated otherwise within this document.
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Additional Information
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'left', fontFamily: "'Poppins',Helvetica,Arial,Lucida,sans-serif", fontSize: '20px', lineHeight:"30px" }}>
          The information contained in the communication with LingoYou Group Srl personnel and its attachments is
          directed exclusively to the recipients indicated therein. Dissemination and communication by persons other
          than the aforementioned addressees is contrary to the rights of confidentiality of correspondence and is
          prohibited by Articles 616 et seq. of the Criminal Code, unless more serious offence is committed. Should this
          communication and its attachments have been received in error by a person other than the
          <br />
          <br />
          above-mentioned addressees, please inform the sender and arrange for the immediate deletion of anything
          wrongly received.
          <br />
          <br />
          Unless otherwise explicitly stated in the content of the communication, it does not constitute any legal
          obligation, but is intended only to initiate or continue a conversation between the parties, without being
          intended to be incorporated in any way into a contractual agreement. The sender does not guarantee, by the
          mere sending of the communication, the correctness, accuracy and timeliness of the information contained
          therein, and shall not be liable in any capacity for omissions or errors of any kind, unless otherwise duly
          signed. The communication may not, however, be used and/or relied upon in part, severed from the entire
          conversation, separately from any attachments and regardless of the date indicated therein.
        </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
